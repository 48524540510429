import React from 'react'
import styles from './VideoСollection.module.scss'
import { motion } from "framer-motion"
import ReactPlayer from 'react-player'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const VideoСollection = () => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}><span>Интервью Анны Петровны </span>телеканалу Russian&#160;Music&#160;Box</motion.h2>
        <motion.div className={styles.item} variants={boxAnimation}>
            <ReactPlayer 
                light={true}
                controls={true}
                playing={true}
                width='100%'
                height='100%'
                className={styles.video} 
                url='https://youtu.be/LbCxhmjcOAw'
                playIcon={<button className={styles.play}>
                    <span className={styles.pulse}></span>
                    <span className={styles.pulse_two}></span>
                </button>}
                
            />
        </motion.div>
        </div>   
    </motion.section>
    )
}