import React from 'react'
import { motion } from "framer-motion"
import styles from './Potansiyel.module.scss'
import image from '../../image/Potansiyel/img_1.jpg'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const Potansiyel = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='AboutUs'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Как узнать свои шансы на<span> получение кредита?</span></h2>
                        <p className={styles.text}>&#8195;Для того чтобы понять, есть ли шансы на получение кредита необходимо проверить кредитную историю в Кредитных бюро и на ФССП:</p>
                        <ul className={styles.box_link}>
                            <li className={styles.item}>
                                <a className={styles.link} href="https://person.nbki.ru/login" target="_blank" rel='noopener noreferrer' aria-label='НБКИ'>НБКИ</a>
                            </li>
                            <li className={styles.item}>
                                <a className={styles.link} href="https://bki-okb.ru/" target="_blank" rel='noopener noreferrer' aria-label='ОКБ'>ОКБ</a>
                            </li>
                            <li className={styles.item}>
                                <a className={styles.link} href="https://online.scoring.ru/login" target="_blank" rel='noopener noreferrer' aria-label='ОКБ'>СБ</a>
                            </li>
                            <li className={styles.item}>
                                <a className={styles.link} href="https://fssp.gov.ru/iss/ip" target="_blank" rel='noopener noreferrer' aria-label=' ФССП'>ФССП</a>
                            </li>
                        </ul>
                    </motion.div>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
            </div>
        </motion.section>
    )
}