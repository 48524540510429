import React from 'react'
import styles from './Tarifeler.module.scss'
import { motion } from "framer-motion"


const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .6}
    } 
}

export const Tarifeler = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Tarify'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Услуги и<span> цены</span></motion.h2>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                            <p className={styles.subtitle}>Консультация по Ипотеке, Рефинансированию и Потребительскому кредитованию</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для всех регионов</p>
                                    <p className={styles.price}>5 000 ₽</p>
                                </div>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.subtitle}>Одобрение ипотечного кредита</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для регионов</p>
                                    <p className={styles.price}>30 000 ₽</p>
                                </div>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для Москвы, МО, Санкт-Петербурга, Лен. области</p>
                                    <p className={styles.price}>40 000 ₽</p>
                                </div>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.subtitle}>Одобрение нецелевого ипотечного кредита</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для регионов</p>
                                    <p className={styles.price}>40 000 ₽</p>
                                </div>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для Москвы, МО, Санкт-Петербурга, Лен. области</p>
                                    <p className={styles.price}>50 000 ₽</p>
                                </div>
                            </div>
                            
                        </li>
                        <li className={styles.item}>
                            <p className={styles.subtitle}>Одобрение рефинансирования</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для регионов</p>
                                    <p className={styles.price}>40 000 ₽</p>
                                </div>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для Москвы, МО, Санкт-Петербурга, Лен. области</p>
                                    <p className={styles.price}>50 000 ₽</p>
                                </div>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.subtitle}>Рефинансирование с дополнительной суммой</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для регионов</p>
                                    <p className={styles.price}>20 000 ₽ +1% доп.суммы</p>
                                </div>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для Москвы, МО, Санкт-Петербурга, Лен. области</p>
                                    <p className={styles.price}>25 000 ₽ +1% доп.суммы</p>
                                </div>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.subtitle}>Юридическое сопровождение сделки</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для регионов</p>
                                    <p className={styles.price}>50 000 ₽</p>
                                </div>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для Москвы, МО, Санкт-Петербурга, Лен. области</p>
                                    <p className={styles.price}>50 000 ₽</p>
                                </div>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.subtitle}>Бизнес кредит для ООО и ИП</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для всех регионов</p>
                                    <p className={styles.price}>1% от суммы</p>
                                </div>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.subtitle}>Одобрение потребительского кредита</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для всех регионов</p>
                                    <p className={styles.price}>10% от суммы</p>
                                </div>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.subtitle}>Одобрение кредита под Залог автомобиля</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для всех регионов</p>
                                    <p className={styles.price}>10% от суммы</p>
                                </div>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.subtitle}>Оформление Страхового полиса</p>
                            <div className={styles.box}>
                                <div className={styles.item_box}>
                                    <p className={styles.text} >Для всех регионов</p>
                                    <p className={styles.price}>5 000 ₽</p>
                                </div>
                            </div>
                        </li>
                    </motion.ul>
            </div>   
        </motion.section>
    )
}