import React from 'react'
import { motion } from "framer-motion"
import styles from './Training.module.scss'
import image from '../../image/Training/img_2.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const Training = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='Obuchenie'             
            >
            <div className={styles.container}>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Как получить новую Престижную профессию - <span>Специалиста по Ипотеке?</span></h2>
                        <p className={styles.text}>&#8195;Пройдите Авторский курс Специалиста по ипотеке и другие обучения по Потребительскому кредитованию, по Юридическому сопровождению сделок, по Страхованию, по Продвижению специалистов по ипотеке от Профессионального эксперта с многолетним опытом работы в сфере кредитования и обучения Цыбиной Анны Петровны.</p>
                        <a className={styles.link} href="https://ipotekaannapetrovna.ru/ipoteka_tsybina"  target='_blank' rel='noopener noreferrer' >подробнее</a>
                    </motion.div>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
            </div>
        </motion.section>
        
        
    )
}