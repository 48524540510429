import React from 'react'
import { motion } from "framer-motion"
import styles from './Partners.module.scss'
import icon_1 from '../../image/Partners/img_1.webp'
import icon_2 from '../../image/Partners/img_2.webp'
import icon_3 from '../../image/Partners/img_3.webp'
import icon_4 from '../../image/Partners/img_4.webp'
import icon_7 from '../../image/Partners/img_7.webp'
import icon_8 from '../../image/Partners/img_8.webp'
import icon_10 from '../../image/Partners/img_10.webp'
import icon_11 from '../../image/Partners/img_11.webp'
import icon_12 from '../../image/Partners/img_12.webp'
import icon_15 from '../../image/Partners/img_15.png'
import icon_16 from '../../image/Partners/img_16.png'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .6}
    } 
}

export const Partners = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='Partners'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}} 
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Банки-<span>партнеры</span></motion.h2>
                    <motion.ul className={styles.list_text} variants={boxAnimation}>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Сбербанк</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Газпромбанк</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Альфа-банк</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Росбанк</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Совкомбанк</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Абсолют банк</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Дом РФ</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>ВТБ</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Ипотека 24</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Ак Барс Банк</h3>
                        </li>
                        <li className={styles.item_box}>
                            <div className={styles.item_circle}> </div>
                            <h3 className={styles.item_text}>Транскапиталбанк</h3>
                        </li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}

/*<li className={styles.item}>
<img className={styles.item_image}  src={icon_1} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_2} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_4} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_3} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_7} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_8} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_11} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_10} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_12} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_15} alt='банк'/>
</li>
<li className={styles.item}>
<img className={styles.item_image}  src={icon_16} alt='банк'/>
</li>*/