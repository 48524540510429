import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img_2.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='AboutUs'             
            >
            <div className={styles.container}>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>О нашей <span>компании</span></h2>
                        <p className={styles.text}>&#8195;Уважаемые клиенты и партнёры, рада приветствовать Вас от лица всей нашей дружной и профессиональной команды.</p>
                        <p className={styles.text}>&#8195;Я Цыбина Анна Петровна, являюсь Создателем и Руководителем двух Ипотечных центров в Москве и в Дубае и Руководителем Учебного Ипотечного центра в Москве. В сфере Ипотеки работаю с 2006 года в ведущих банках страны, в том числе в Центральном Банке Российской Федерации.</p>
                        <p className={styles.text}>&#8195;Наша компания занимается Одобрением Ипотеки, Потребительских кредитов, Рефинансированием для Всех клиентов России и других Стран.</p>
                        <p className={styles.text}>&#8195;Мы являемся Крупнейшим Партнером Ведущих Банков Страны, Агенств Недвижимости, Строительных компаний и Застройщиков.</p>
                        <p className={styles.text}>&#8195;Для наших клиентов действуют скидки к ставкам и лояльные условия одобрения Кредитных продуктов.</p>
                        <p className={styles.text}>&#8195;Наша Главная Задача чтобы все наши клиенты были Счастливы в своей Недвижимости!</p>
                    </motion.div>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
            </div>
        </motion.section>
        
        
    )
}