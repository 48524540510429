import React from 'react'
import styles from './Certificates.module.scss'
import { motion } from "framer-motion"
import { arrCertificates } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'
import { CardImage } from '../CardImage/CardImage'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Certificates = ({isImageOpen}) => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Наши <span> сертификаты</span></motion.h2>
        <motion.p className={styles.text} variants={titleAnimation}>для просмотра всех сертификатов кликните по любому из них</motion.p>
        <motion.ul className={styles.list} variants={boxAnimation}>
                {arrCertificates.map((item, index) =>{
                        const keyUid = uuidv4()
                            if (index  <= 3) {
                                return(
                                    <CardImage
                                    key={keyUid}
                                    item={item}
                                    index={index}
                                    isImageOpen={isImageOpen}
                                    arr={arrCertificates} 
                                />
                                ) 
                            }else {
                                return ''
                            }
                    })}
                </motion.ul>
        </div>   
    </motion.section>
    )
}