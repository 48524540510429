import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Почему <span> выбирают нас?</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <p className={styles.number}>01</p>
                        <h3 className={styles.item_title}>Выгодные программы</h3>
                        <p className={styles.item_text}>Мы сотрудничаем с Ведущими Банками страны, большинство которых предоставляют для наших клиентов скидки к ставкам и одобряют заявки наших клиентов в приоритете. Одобрим Вам самую выгодную программу. </p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>02</p>
                        <h3 className={styles.item_title}>Высокая оперативность</h3>
                        <p className={styles.item_text}>Сотрудничая с нами, Вы сэкономите время и свои денежные средства, так как Вам не нужно будет оплачивать  Дополнительные банковские услуги на Сделке.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>03</p>
                        <h3 className={styles.item_title}>Больше шансов</h3>
                        <p className={styles.item_text}>Одобрение получают практически 100% наших клиентов. Благодаря отличной деловой репутации и многолетнему опыту работы в Ипотеке наша компания добивается Положительного решения для всех наших клиентов даже в самых сложных ситуациях.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>04</p>
                        <h3 className={styles.item_title}>Индивидуальный подход</h3>
                        <p className={styles.item_text}>Мы индивидуально подходим к каждому клиенту и выполняем полное сопровождение – от выбора банка и кредитной программы до выдачи денежных средств. Мы контролируем ситуацию на всех этапах сделки.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>05</p>
                        <h3 className={styles.item_title}>Подготовка документов</h3>
                        <p className={styles.item_text}>В каждом Банке свой порядок заполнения анкеты и подготовки документов для Одобрения. Мы поможем профессионально подготовить всю документацию для получения 100% Положительного решения.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>06</p>
                        <h3 className={styles.item_title}>Оплата за результат</h3>
                        <p className={styles.item_text}>Оплата за Одобрение только после получения Положительного решения в Банках-Партнёрах. После Одобрения за каждым клиентом Индивидуально закрепляется Личный менеджер Банка.</p>
                    </li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}