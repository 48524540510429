import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import { Questions } from '../../components/Questions/Questions'
import {Advantages} from '../../components/Advantages/Advantages'
import {Partners} from '../../components/Partners/Partners'
import { Potansiyel } from '../../components/Potansiyel/Potansiyel'
import { Tarifeler } from '../../components/Tarifeler/Tarifeler'
import { Answer } from '../../components/Answer/Answer'
import { Training } from '../../components/Training/Training'
import { Certificates } from '../../components/Certificates/Certificates'
import {Reviews} from '../../components/Reviews/Reviews'
import { VideoСollection } from '../../components/VideoСollection/VideoСollection'
import { Biography } from '../../components/Biography/Biography'

export const Main = ({isOpen, isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <SliderAdvantages/>
            <AboutUs
                isOpen={isOpen}
            />
            <Advantages
                isOpen={isOpen}
            />
            <Training/> 
            <Tarifeler/>
            <Potansiyel/>
            <Questions/>
            <SliderAdvantages/>
            <Answer
                isOpen={isOpen}
            />
            <Biography/>
            <Certificates
                isImageOpen={isImageOpen}
            />
            <VideoСollection/>
            <Partners/>
            <Reviews
                isImageOpen={isImageOpen}
            />
        </section>
    )
}