import React from 'react'
import styles from './Biography.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Biography = () => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Руководитель Ипотечного центра кредитования<span> Цыбина Анна Петровна</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>С 2006 года</span> работает в банковской сфере ипотечного кредитования;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>В 2008 году</span> стала бизнес-тренером в итальянском банке Интеза, а также руководителем ипотечного кредитования. Обучала сотрудников банков по всей стране;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>В 2009 году</span> прошла обучение в международной компании Ars Vitae и получила сертификат бизнес-тренера;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>С 2011 года</span> работала руководителем ипотечного центра в банке ВТБ и одновременно бизнес-тренером;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>С 2013 года</span> работала в Центральном банке РФ в управлении 12 регионами страны. В подчинении было более 1000 человек;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>В 2020 году</span> запустила свой бизнес по ипотечному кредитованию и открыла офис в Москва-Сити;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>В 2022 году</span> открыла офис в Дубае и запустила проект по обучению специалистов по ипотеке;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>В 2023 году</span> подписала контракты по сотрудничеству с Бали, Кипром и Турцией. Открыла международный отдел продаж по ипотеке;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}>Дала Интервью Телеканалу Russian Music Box;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>В 2024 году</span> стала Официальным экспертом Федеральных СМИ по Ипотеке в Российской Федерации (Forbes, РБК, Аргументы и факты);</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}>Сотрудничает с ведущими банками страны, агентствами недвижимости, застройщиками, строительными и страховыми компаниями;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>500+ учеников.</span> Есть специалисты, которые вышли на доход от 3 млн. руб. по ипотеке, благодаря знаниям с курса;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}>Клиенты получают <span>100% одобрение</span> ипотеки на выгодных условиях;</p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.text}><span>1500+</span> положительных отзывов от учеников и клиентов.</p>
            </li>
        </motion.ul>
        </div>   
    </motion.section>
    )
}