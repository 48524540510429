import React, {useCallback} from 'react'
import styles from './CardReviews.module.scss'


export const CardReviews = ({item, isImageOpen, index, arr}) => {

    const cardClick = useCallback(() => {
        isImageOpen(index, arr)
    }, [isImageOpen, arr, index])

    return (
        <div className={styles.item}
            onClick={cardClick}
            >
            <div className={styles.box_image}>
                <img className={styles.image} src={item.image} alt={item.title}/>
            </div>
        </div>
    )
}