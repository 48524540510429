import React, {useState, useEffect, useCallback} from 'react'
import styles from './Reviews.module.scss'
import { motion } from "framer-motion"
import { arrReviews } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'
import { CardReviews } from '../CardReviews/CardReviews'
import ScreenSize from '../../hooks/ScreenSize'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Reviews = ({isImageOpen}) => {

    const width = ScreenSize()
    const [moviesTotal, setMoviesTotal] = useState(0)
    const [addMovies, setAddMovies] = useState(0)



    useEffect(() => {
        const getCards = () => {
            if (width >= 910) {
                setMoviesTotal(8);
                setAddMovies(4);
                } else if (width < 910 && width >= 670) {
                    setMoviesTotal(6);
                    setAddMovies(3);
                        } else if (width < 670 && width >= 450) {
                            setMoviesTotal(6);
                            setAddMovies(2);
                        } else if (width < 450) {
                            setMoviesTotal(4);
                            setAddMovies(1);
                        }
        }
        getCards();
    }, [width])

    const addCards = useCallback(() => {
        setMoviesTotal(moviesTotal + addMovies);
    }, [moviesTotal, addMovies])

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Отзывы <span>клиентов и партнеров</span></motion.h2>
        <motion.p className={styles.text} variants={titleAnimation}>для просмотра всех отзывов кликните по любому из них</motion.p>
        <motion.ul className={styles.list} variants={boxAnimation}>
                {arrReviews.map((item, index) =>{
                        const keyUid = uuidv4()
                            if (index + 1 <= moviesTotal) {
                                return(
                                    <CardReviews
                                    key={keyUid}
                                    item={item}
                                    index={index}
                                    isImageOpen={isImageOpen}
                                    arr={arrReviews} 
                                />
                                ) 
                            }else {
                                return ''
                            }
                    })}
                </motion.ul>
                {moviesTotal < arrReviews.length && <button  className={styles.button} onClick={addCards}>Ещё отзывы</button>}
        </div>   
    </motion.section>
    )
}