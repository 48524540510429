import React from 'react'
import { motion } from "framer-motion"
import styles from './Answer.module.scss'
import image from '../../image/Answer/img.jpg'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const Answer = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Остались вопросы по <span>Ипотеке или Потребительским кредитам?</span></h2>
                        <p className={styles.text}>&#8195;Если у Вас остались вопросы по Кредитованию или по Услугам нашей Компании, мы всегда рады помочь и предоставить вам необходимую информацию. Мы готовы ответить на все Ваши любые вопросы и помочь в выборе подходящего продукта и банка. Напишите нам и мы оперативно запишем Вас на Консультацию.</p>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79586509502' aria-label='связаться вацап'>задать вопрос</a>
                    </motion.div>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
            </div>
        </motion.section>
        
        
    )
}